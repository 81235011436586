<template>
  <div class="readings">
    <v-container fluid class="subheading pt-0">
      <h1>
        {{ userdata.name }} &ndash;
        {{ userdata.house_number + " " + userdata.street }}
      </h1>
      <v-layout row>
        <v-flex xs12 class="text-xs-right">
          <ReadingNew @readingAdded="snackbar = true" />
        </v-flex>
      </v-layout>
      <metercolheads />
      
      <v-card flat v-for="(reading) in readings" :key="reading.id">
        <v-layout row wrap class="pa-2 pl-3 pr-3">
          <v-flex md2 xs4>
            <div class="text-xs-left">
              <p>
                {{ reading.created_at | formatDate }} <br />
                ({{ reading.created_at | whatQuarter }})
              </p>

              <!-- <div v-if="todayDate == reading.created_at">
                {{ storeReading(reading) }}
                <ReadingEdit @readingEdit="snackbar = true" />
              </div> -->
              <!-- <span v-if="index == 0">{{ storePrevData(reading) }}</span> -->

            </div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="text-xs-right">{{ reading.meter_reading }}</div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="text-xs-right">{{ reading.units_used }}</div>
          </v-flex>
          <v-flex md2 class="hidden-sm-and-down">
            <div class="text-xs-right">{{ reading.units_daily }}</div>
          </v-flex>
          <v-flex md2 class="hidden-sm-and-down">
            <div class="text-xs-right">{{ reading.unit_price }}</div>
          </v-flex>
          <v-flex md2 xs12 class="text-xs-right">
            <v-tooltip
              left
              class="subheading font-weight-bold"
              color="grey darken-4"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  class="subheading white--text font-weight-bold bt-0"
                  v-on="on"
                  v-if="reading.payment_confirmed"
                >
                  {{ reading.amount_due }}
                  <v-icon right dark>check_circle</v-icon>
                </v-btn>
                <v-btn
                  color="red"
                  class="subheading white--text font-weight-bold"
                  v-on="on"
                  v-else
                >
                  {{ reading.amount_due }}
                  <v-icon right dark>fiber_manual_record</v-icon>
                </v-btn>
              </template>
              <span>
                <div
                  v-if="reading.notes"
                  class="subheading font-weight-bold mt-2"
                >
                  <p>
                    <span class="font-weight-regular">Note:</span>
                    {{ reading.notes }}
                  </p>
                  <hr />
                </div>

                <div
                  v-if="reading.payment_confirmed"
                  class="subheading font-weight-bold mt-2"
                >
                  <p>
                    <span class="font-weight-regular">Payment</span>
                    <br />
                    <span class="font-weight-regular">Confirmed:&nbsp;</span>
                    {{ reading.payment_confirmed | formatDate }}
                    <br />
                    <span class="font-weight-regular">Method:&nbsp;</span>
                    <span v-if="reading.payment_method">{{
                      reading.payment_method
                    }}</span>
                    <span v-else>N/A</span>
                    <br />
                    <span class="font-weight-regular">Notes:&nbsp;</span>
                    <span v-if="reading.payment_notes">{{
                      reading.payment_notes
                    }}</span>
                    <span v-else>N/A</span>
                  </p>
                </div>
                <div v-else class="subheading font-weight-bold">
                  Not Yet Confirmed
                </div>
              </span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import metercolheads from "@/components/Includes/MeterColHeads"
import ReadingNew from "@/components/ReadingNew"
// import ReadingEdit from "@/components/ReadingEdit"
import db from "@/fb"
import firebase from "firebase/app"
import "firebase/auth"
import mixin from "@/mixin.js"
import moment from "moment"

export default {
  mixins: [mixin],
  components: {ReadingNew,metercolheads },
  data: function() {
    return {
      readings: [],
      maxMeter: 0,
      todayDate: 0,
    }
  },
  methods: {
    getUserInfo(theEmail) {
      var docRef = db.collection("validusers").doc(theEmail)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.$store.dispatch("newUserData", doc.data())
            this.$store.dispatch("newUserHouseNumber", doc.data().house_number)
            this.$store.dispatch("newUserName", doc.data().name)
            this.makeReadingsList(doc.data().house_number)
            //  this.makeReadingsList("17505")  //for testing
          } else {
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    },
    storeReading(reading) {
      this.$store.dispatch("newReadingData", reading)
    },
    storePrevData(reading) {
      this.$store.dispatch("newPrevData", reading)
    },
    storeMaxReading(reading) {
      this.$store.dispatch("newMaxReading", reading)
    },
    makeReadingsList(house_num) {
      db.collection("readings")
        .orderBy("created_at", "asc")
        .where("house_number", "==", house_num)
        .onSnapshot((res) => {
          const changes = res.docChanges()
          changes.forEach((change) => {
            if (change.type === "added") {
              this.readings.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              })

// console.log(this.maxMeter + " - " + change.doc.data().meter_reading + " -- " + change.doc.data().created_at);

              // if (change.doc.data().meter_reading > this.maxMeter) {
                // this.maxMeter = change.doc.data().meter_reading
                this.$store.dispatch(
                  "newMaxReading",
                  change.doc.data().meter_reading
                )
                this.$store.dispatch(
                  "newDaysSince",
                  this.dayCount(change.doc.data().created_at)
                )
              // }
            }
          })
        })
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true
      this.currentUser = firebase.auth().currentUser.email

      this.theUserInfo = this.getUserInfo(this.currentUser)

      this.todayDate = moment(Date.parse(new Date().toString())).format(
        "YYYY-MM-DD"
      )
    }
  },
  computed: {
    userdata() {
      return this.$store.getters.userdata
    },
  },
}
</script>
