<template>
  <div>
    <div
      id="chart"
      ref="chart"
      style="width: 600px; height: 380px; margin: 0 auto;"
    >
      <apexcharts
        type="pie"
        :options="chartOptions"
        :series="this.$store.getters.seriesTemp"
        :width="600"
        :height="380"
      ></apexcharts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import mixin from "@/mixin.js"

export default {
  mixins: [mixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data: function() {
    return {
      isHidden: false,
    }
  },
  methods: {},
  computed: {
    chartOptions: function() {
      return {
        labels: this.$store.getters.labelsTemp,
        legend: {
          position: "right",
          horizontalAlign: "left",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      }
    },
  },
}
</script>
