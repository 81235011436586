<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Added to database.</span>
      <v-btn flat color="white" @click="snackbar=false">Close</v-btn>
    </v-snackbar>

    <v-toolbar app flat class="toolbarWidth">
      <v-toolbar-title class="text-uppercase grey--text titleSize">
        <span>Ramadero</span>
        <br />
        <span class="font-weight-light">Water Association</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="pageslinks">
        <router-link
          to="/readingsAll"
          color="primary"
          class="gray--text"
          v-if="showStats"
          :class="{ active: hiliteStats }"
        >
          <v-btn rounded>Community Stats</v-btn>
        </router-link>
        <router-link
          to="/readings"
          color="primary"
          class="gray--text"
          v-if="showMine"
          :class="{ active: hiliteMine }"
        >
          <v-btn rounded>My Stats</v-btn>
        </router-link>
        <router-link
          to="/contacts"
          color="primary"
          class="gray--text"
          v-if="showUsers"
          :class="{ active: hiliteUsers }"
        >
          <v-btn rounded>Contacts</v-btn>
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <v-btn flat class="white grey--text" v-if="showLogin">
        <router-link to="/login">Log In</router-link>
        <v-icon right>event_</v-icon>
      </v-btn>

      <v-btn flat class="white grey--text" v-if="showSignUp">
        <router-link to="/signup">Sign Up</router-link>
        <v-icon right>event_available</v-icon>
      </v-btn>

      <v-btn flat class="grey white--text" v-on:click="logout" v-if="showLogout">
        Log Out
        <v-icon right>event_busy</v-icon>
      </v-btn>
    </v-toolbar>
  </nav>
</template>


<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "navbar",
  components: { firebase },
  data() {
    return {
      isLoggedIn: false,
      currentUser: false,
      drawer: false,
      snackbar: false,
      showLogin: false,
      showSignUp: true,
      showLogout: false,
      showStats: false,
      showMine: false,
      showUsers: false,
      hiliteStats: false,
      hiliteMine: false,
      hiliteusers: false
    };
  },
  created() {
    if (firebase.auth().currentUser) {
      // window.addEventListener(
      //   "beforeunload",
      //   function(event) {
      //     this.logout;
      //   },
      //   false
      // );
      this.isLoggedIn = true;
      this.currentUser = firebase.auth().currentUser.email;
      if (
        this.$route.path === "/contacts" ||
        this.$route.path === "/readingsAll" ||
        this.$route.path === "/readings"
      ) {
        this.showLogin = false;
        this.showSignUp = false;
        this.showLogout = true;
        this.showStats = true;
        this.showMine = true;
        this.showUsers = true;
      }
      if (this.$route.path === "/login" || this.$route.path === "/signup") {
        this.showLogout = false;
        this.showStats = false;
        this.showMine = false;
        this.showUsers = false;
      }
      if (this.$route.path === "/contacts") {
        this.hiliteStats = false;
        this.hiliteMine = false;
        this.hiliteUsers = true;
      }
      if (this.$route.path === "/readingsAll") {
        this.hiliteStats = true;
        this.hiliteMine = false;
        this.hiliteUsers = false;
      }
      if (this.$route.path === "/readings") {
        this.hiliteStats = false;
        this.hiliteMine = true;
        this.hiliteUsers = false;
      }
      if (this.$route.path === "/login") {
        this.showLogin = false;
        this.showSignUp = true;
      }
      if (this.$route.path === "/signup") {
        this.showLogin = true;
        this.showSignUp = false;
      }
    }
  },
  mounted() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
    }
  },
  methods: {
    // beforeunload: function(event) {
    //   this.logout;
    // },
    logout: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.go({ path: this.$router.path });
        });
    }
  },
  watch: {
    $route() {
      if (
        this.$route.path === "/contacts" ||
        this.$route.path === "/readingsAll" ||
        this.$route.path === "/readings"
      ) {
        this.showLogin = false;
        this.showSignUp = false;
        this.showLogout = true;
        this.showStats = true;
        this.showMine = true;
        this.showUsers = true;
      }
      if (this.$route.path === "/login" || this.$route.path === "/signup") {
        this.showLogout = false;
        this.showStats = false;
        this.showMine = false;
        this.showUsers = false;
      }
      if (this.$route.path === "/contacts") {
        this.hiliteStats = false;
        this.hiliteMine = false;
        this.hiliteUsers = true;
      }
      if (this.$route.path === "/readingsAll") {
        this.hiliteStats = true;
        this.hiliteMine = false;
        this.hiliteUsers = false;
      }
      if (this.$route.path === "/readings") {
        this.hiliteStats = false;
        this.hiliteMine = true;
        this.hiliteUsers = false;
      }
      if (this.$route.path === "/login") {
        this.showLogin = false;
        this.showSignUp = true;
      }
      if (this.$route.path === "/signup") {
        this.showLogin = true;
        this.showSignUp = false;
      }
    }
  }
};
</script>

<style scoped>
.toolbarWidth {
  width: 1000px;
  top: auto;
  left: unset;
}
.titleSize {
  line-height: 1em;
}
.pageslinks {
  top: 17px;
  position: relative;
}
.pageslinks button {
  box-shadow: none !important;
  border: 3px solid gray;
  border-bottom-color: gray;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 36px;
}
.pageslinks .active button {
  box-shadow: none;
  border-bottom-color: transparent;
  height: 56px;
  font-size: 1.2em;
  font-weight: bold;
}
nav {
  border-bottom: 3px solid gray;
  /* padding-bottom: 1em; */
}
</style>