import Vue from 'vue';
import "firebase/auth";
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);


export default {
    data: function () {
        return {
            symbols: [],
            userInfo: {},
            series: [],
            labels: [],
        }
    },
    methods: {
        getLastName(name) {
            if (name != undefined) {
                let tempStr = name.split(" ");
                return tempStr[1];
            }
        },
        dayCount(lstDateRead) {
            var now = new Date();
            lstDateRead = new Date(lstDateRead);
            return parseInt((now - lstDateRead) / (1000 * 60 * 60 * 24));
        }

    },
    computed: {

    }
}