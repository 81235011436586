<template>
  <v-app class="grey lighten-4">
    <Navbar class="navbar" />
    <v-content class="mx-4 mt-4">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: { Navbar },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
body {
  width: 1000px;
  margin: 0 auto;
  background-color: lightgray;
}
</style>