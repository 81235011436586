import Vue from 'vue';
import Router from 'vue-router';
import Readings from './views/Readings';
import ReadingsAll from './views/ReadingsAll';
import ReadingsCommunity from './views/ReadingsCommunity';
import ImportDB from './components/ImportDB';
import Invaliduser from './views/Invaliduser';
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import Contacts from './views/Contacts';
import firebase from "firebase/app";


Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'base',
    component: Readings,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/import',
    name: 'import',
    component: ImportDB,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  }, {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      requiresGuest: true
    }
  }, {
    path: '/readings',
    name: 'readings',
    component: Readings,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/readingsAll',
    name: 'readings_all',
    component: ReadingsAll,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/readingsCommunity',
    name: 'readings_community',
    component: ReadingsCommunity,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/invaliduser',
    name: 'invaliduser',
    component: Invaliduser,
    meta: {
      requiresAuth: true
    }
  }]
});

router.beforeEach((to, from, next) => {
  let reqAuth = to.matched.some(record => record.meta.requiresAuth);
  let reqGuest = to.matched.some(record => record.meta.requiresGuest);
  let curUsr = firebase.auth().currentUser;
  let thePath = null;
  // let toFullPath = to.fullPath;

  if (reqAuth && !curUsr) {
    thePath = '/login';
  } else if (reqGuest && curUsr) {
    thePath = '/';
  } else {
    // toFullPath = null;
  }

  next({
    path: thePath,
    query: {
      // redirect: toFullPath
    }
  });

});

export default router;