import Vue from "vue"
import "./plugins/vuetify"
import App from "./App.vue"
import router from "./router"
import firebase from "firebase/app"
import "./fb"
import VModal from "vue-js-modal"
import moment from "moment"
import {
  store
} from "./store/store"

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(Date.parse(value)).format("MMM DD, YYYY");
  }
});

Vue.filter("whatQuarter", function (value) {
  let allQtrs = "dec-jan, mar-jun, jul-sep, oct-dec";
  if (value) {
    let theYear = moment(Date.parse(value)).format("YYYY");
    let theQtr = moment(Date.parse(value)).quarter();
    let theMonth = 1 + moment(Date.parse(value)).month();
    if (theMonth % 3 > 0) {
      --theQtr;
    }
    if (theMonth == 1) {
      theQtr = 4;
      --theYear;
    }
    return "Q" + theQtr + ", " + theYear;

  }
});

Vue.use(VModal)

Vue.config.productionTip = false

let app
firebase.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      el: "#app",
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app")
  }
})