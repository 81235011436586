<template>
  <h1>INVALID USER</h1>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "invaliduser",
  components: { firebase },
  data() {},
  created: function() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.$router.go({ path: this.$router.path });
      });
  },
  methods: {}
};
</script>

<style>
</style>
