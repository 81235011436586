<template>
  <div class="contacts">
    <v-container fluid class="subheading pt-0">
      <h1>Contacts list</h1>
      <p></p>
      <v-card flat v-for="(contact, index) in contacts" :key="index">
        <v-layout row wrap class="pa-2 pl-3 pr-3">
          <v-flex md3 xs4>
            <div class="text-xs-left">
              <strong>{{ contact.name }}</strong>
            </div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="text-xs-left">{{ refHouseNum = contact.house_number }} {{ contact.street }}</div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="text-xs-left">{{ contact.phone }}</div>
          </v-flex>
          <!-- <v-flex md2 xs4>
            <div class="text-xs-left">{{ contact.status }}</div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="text-xs-left">{{ contact.type }}</div>
          </v-flex>-->
          <v-flex md1 xs4>
            <div class="text-xs-left">
              <a v-bind:href=" 'mailto:' + contact.id">{{contact.id }}</a>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import db from "@/fb";
import firebase from "firebase/app";
import "firebase/auth";
import mixin from "@/mixin.js";

export default {
  mixins: [mixin],
  data: function() {
    return {
      contacts: [],
      refHouseNum: 0
    };
  },
  methods: {
    makeUsersList() {
      db.collection("validusers")
        .orderBy("house_number", "desc")
        .onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === "added") {
              this.contacts.unshift({
                ...change.doc.data(),
                id: change.doc.id
              });
            }
          });
        });
    }
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUser = firebase.auth().currentUser.email;
      this.makeUsersList();
    }
  }
};
</script>
