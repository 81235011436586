<template>
  <div class="readings">
    <v-container fluid class="subheading pt-0">
      <h1>Water use by household for approximently 3 months</h1>
      <p></p>
      <div>
        <v-layout row>
          <v-flex xs3 class>
            <h2>$200 Yearly</h2>
            <table class="table">
              <tr>
                <th class="overdue">Payment Outstanding</th>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>all are current</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th class="complete">Payment Complete</th>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Orban</li>
                    <li>Swannack, C.</li>
                    <li>Swannack, E.</li>
                    <li>Tyler</li>
                    <li>Berumen</li>
                    <li>Baumanis</li>
                  </ul>
                </td>
              </tr>
            </table>
          </v-flex>
          <v-flex xs1 class></v-flex>
          <v-flex xs8 class>
            <keep-alive>
              <ReadingsChart v-if="showMeSomePie" />
            </keep-alive>
          </v-flex>
        </v-layout>
        <metercolheads />
        <span v-for="reading in readings" :key="reading.id">
          <v-card flat>
            <h3 md12>
              &nbsp; {{ reading.house_number }}
              <span v-if="reading.house_number == 2700">Tierra Way</span>
              <span v-else>Ramadero Way</span>
              <span>
                &nbsp; &ndash; &nbsp;
                {{ getLastName(reading.user_name) }}
              </span>
            </h3>

            <v-layout row wrap class="pa-2 pl-3 pr-3">
              <v-flex md3 xs3>
                <div class="text-xs-left">
                  {{ reading.created_at | formatDate }} <br />
                  ({{ reading.created_at | whatQuarter }})
                </div>
              </v-flex>
              <v-flex md3 xs3>
                <div class="text-xs-right">{{ reading.units_used }}</div>
              </v-flex>
              <v-flex md3 class="hidden-sm-and-down">
                <div class="text-xs-right">{{ reading.units_daily }}</div>
              </v-flex>
              <v-flex md3 xs12 class="text-xs-right">
                <v-tooltip
                  left
                  class="subheading font-weight-bold"
                  color="grey darken-4"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      class="subheading white--text font-weight-bold bt-0"
                      v-on="on"
                      v-if="reading.payment_confirmed"
                    >
                      {{ reading.amount_due }}
                      <v-icon right dark>check_circle</v-icon>
                    </v-btn>
                    <v-btn
                      color="red"
                      class="subheading white--text font-weight-bold"
                      v-on="on"
                      v-else
                    >
                      {{ reading.amount_due }}
                      <v-icon right dark>fiber_manual_record</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <div
                      v-if="reading.notes"
                      class="subheading font-weight-bold mt-2"
                    >
                      <p>
                        <span class="font-weight-regular">Note:</span>
                        {{ reading.notes }}
                      </p>
                      <hr />
                    </div>

                    <div
                      v-if="reading.payment_confirmed"
                      class="subheading font-weight-bold mt-2"
                    >
                      <p>
                        <span class="font-weight-regular">Payment</span>
                        <br />
                        <span class="font-weight-regular"
                          >Confirmed:&nbsp;</span
                        >
                        {{ reading.payment_confirmed | formatDate }}
                        <br />
                        <span class="font-weight-regular">Method:&nbsp;</span>
                        <span v-if="reading.payment_method">
                          {{ reading.payment_method }}
                        </span>
                        <span v-else>N/A</span>
                        <br />
                        <span class="font-weight-regular">Notes:&nbsp;</span>
                        <span v-if="reading.payment_notes">
                          {{ reading.payment_notes }}
                        </span>
                        <span v-else>N/A</span>
                      </p>
                    </div>
                    <div v-else class="subheading font-weight-bold">
                      Not Yet Confirmed
                    </div>
                  </span>
                </v-tooltip>
              </v-flex>
              <!-- <div v-if="dayCount(reading.created_at) > 10">LATE</div> -->
            </v-layout>
          </v-card>
          <v-divider></v-divider>
        </span>
      </div>
    </v-container>
  </div>
</template>

<style>
.table li {
}
.complete,
.overdue {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.complete {
  background-color: blue;
}
.overdue {
  background-color: red;
}
</style>

<script>
import db from "@/fb"
import firebase from "firebase/app"
import "firebase/auth"
import mixin from "@/mixin.js"
import metercolheads from "@/components/Includes/MeterColHeads_all"
import moment from "moment"
import ReadingsChart from "@/components/ReadingsChart"

export default {
  mixins: [mixin],
  components: {
    metercolheads,
    ReadingsChart,
  },
  data: function() {
    return {
      readings: [],
      todayDate: 0,
      showMeSomePie: 0,
    }
  },
  methods: {
    getUserInfo(theEmail) {
      var docRef = db.collection("validusers").doc(theEmail)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.getReadingsDocs()
          } else {
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    },
    getReadingsDocs() {
      let refNumber = ""
      let tempSeries = []
      let tempLabels = []
      let streetName = " Tierra Way"
      db.collection("readings")
        .orderBy("house_number", "desc")
        .orderBy("created_at", "desc")
        .onSnapshot((res) => {
          const changes = res.docChanges()
          changes.forEach((change) => {
            if (change.type === "added") {
              let house_num = change.doc.data().house_number
              let unitsDaily = change.doc.data().units_daily
              if (house_num != refNumber) {
                refNumber = house_num
                this.readings.unshift({
                  ...change.doc.data(),
                  id: change.doc.id,
                })
                tempSeries.unshift(parseFloat(unitsDaily))
                if (house_num != "2700") {
                  streetName = " Ramadero Way"
                }
                tempLabels.unshift(
                  this.getLastName(change.doc.data().user_name) +
                    " -- " +
                    house_num +
                    streetName
                )
              }
            }
          })
          this.$store.dispatch("newSeriesTemp", tempSeries)
          this.$store.dispatch("newLabelsTemp", tempLabels)
          this.showMeSomePie = 1
        })
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true
      this.currentUser = firebase.auth().currentUser.email
      this.theUserInfo = this.getUserInfo(this.currentUser)
      this.todayDate = moment(Date.parse(new Date().toString())).format(
        "YYYY-MM-DD"
      )
    }
  },
  computed: {
    userdata() {
      return this.$store.getters.userdata
    },
  },
}
</script>
