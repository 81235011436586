<template>
  <div class="container">
    <div class="row">
      <div class="col s12 m8 offset-m2">
        <div class="signup card-panel grey black--text center lighten-2">
          <div class="ml-4 pt-2 display-2 text-xs-center">Sign Up</div>
          <v-form class="pa-4">
            <v-text-field
              label="Email"
              v-model="email"
              prepend-icon="email"
              :rules="formRules"
              :autocomplete="'username'"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="password"
              prepend-icon="lock"
              :rules="formRules"
              :type="'password'"
              :autocomplete="'new-password'"
            ></v-text-field>
            <v-spacer></v-spacer>
            <div class="text-center">
              <v-btn rounded color="primary" dark @click="signup" :loading="loading">Sign Up</v-btn>
            </div>

            <v-spacer></v-spacer>
            <h3 class="red--text text-xs-center">{{ feedback }}</h3>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import firebase from "firebase/app";
import "firebase/auth";
import db from "@/fb";
import mixin from "@/mixin.js";

export default {
  mixins: [mixin],
  name: "signup",
  data() {
    return {
      email: "",
      password: "",
      formRules: [v => v.length >= 6 || "Minimum is 6 characters."],
      loading: false,
      feedback: null
    };
  },
  methods: {
    signup: function(e) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          data => {
            alert(`Account for ${data.user.email} created`);
            this.$router.go({ path: this.$router.path });
          },
          err => {
            alert(err.message);
          }
        );
      e.preventDefault();
    }
  },
  computed: {}
};
</script>