<template>
  <v-container max-width="600px">
    <v-card>
      <v-card-title>
        <h2>Import Readings From Raw Data</h2>
      </v-card-title>
      <v-card-text>
        <v-form class="pa-4" ref="readingsForm">
          <v-textarea
            label="Database"
            v-model="content_readings"
            prepend-icon="edit"
            :rules="formRules"
          ></v-textarea>

          <v-spacer></v-spacer>
          <v-btn flat class="success mx-0 mt-3" @click="submit_readings" :loading="loading">Import</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <h2>Import Users From Raw Data</h2>
      </v-card-title>
      <v-card-text>
        <v-form class="pa-4" ref="usersForm">
          <v-textarea
            label="Database"
            v-model="content_users"
            prepend-icon="edit"
            :rules="formRules"
          ></v-textarea>

          <v-spacer></v-spacer>
          <v-btn flat class="success mx-0 mt-3" @click="submit_users" :loading="loading">Import</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import db from "@/fb";

export default {
  data() {
    return {
      content_readings: "",
      content_users: "",
      formRules: [v => v.length >= 3 || "Minimum is 3 characters."],
      loading: false,
      dialog: false
    };
  },

  methods: {
    submit_users() {
      if (this.$refs.usersForm.validate()) {
        console.log("gotHere");

        var lines = this.content_users.split("\n");
        for (var i = 0; i < lines.length; i++) {
          let dataSet = lines[i].split(",");
          let email = dataSet[4];
          let user = {
            house_number: dataSet[0],
            street: dataSet[1],
            status: dataSet[2],
            type: dataSet[3],
            name: dataSet[5],
            phone: dataSet[6]
          };
          db.collection("validusers")
            .doc(email)
            .set(user)
            .then(() => {
              this.loading = false;
              this.dialog = false;
            });
        }
      }
    },

    submit_readings() {
      if (this.$refs.readingsForm.validate()) {
        var lines = this.content_readings.split("\n");
        for (var i = 0; i < lines.length; i++) {
          let dataSet = lines[i].split(",");
          let reading = {
            index_id: dataSet[1].trim() + "." + dataSet[2].trim(),
            house_number: dataSet[1].trim(),
            meter_reading: dataSet[2].trim(),
            units_used: dataSet[3].trim(),
            amount_due: dataSet[4].trim(),
            unit_price: dataSet[5].trim(),
            units_daily: dataSet[6].trim(),
            payment_confirmed: dataSet[11].trim(),
            payment_method: dataSet[8].trim(),
            payment_notes: dataSet[9].trim(),
            notes: dataSet[10].trim(),
            created_at: dataSet[11].trim(),
            updated_at: dataSet[12].trim()
          };
          db.collection("readings")
            .add(reading)
            .then(() => {
              this.loading = false;
              this.dialog = false;
              // this.$emit("readingAdded");
            });
        }
      }
    }
  }
};
</script>