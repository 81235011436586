<template>
    <v-card flat>
        <v-layout row wrap class="pa-2 pl-3 pr-3">
          <v-flex md2 xs4>
            <div class="caption grey--text">Created</div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="caption grey--text text-xs-right">Meter Reading</div>
          </v-flex>
          <v-flex md2 xs4>
            <div class="caption grey--text text-xs-right">Units Used</div>
          </v-flex>
          <v-flex md2 class="hidden-sm-and-down">
            <div class="caption grey--text text-xs-right">Units Daily</div>
          </v-flex>
          <v-flex md2 class="hidden-sm-and-down">
            <div class="caption grey--text text-xs-right">Unit Price</div>
          </v-flex>
          <v-flex md2  class="hidden-sm-and-down">
            <div class="caption grey--text text-xs-center">Amount Due</div>
          </v-flex>
        </v-layout>
      </v-card>
</template>

<script>
export default {
  name: "metercolheads",
}
</script>

<style>

</style>
