import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        userName: "",
        userHouseNumber: "",
        maxReading: 0,
        prevData: "",
        unit_price: 0.26,
        daysSince: 0,
        userdata: {},
        readingData: {},
        seriesTemp: [],
        labelsTemp: []
    },
    mutations: {
        newUserHouseNumber(state, payload) {
            state.userHouseNumber = payload;
        },
        newUserName(state, payload) {
            state.userName = payload;
        },
        newUserData(state, payload) {
            state.userdata = payload;
        },
        newMaxReading(state, payload) {
            state.maxReading = payload;
        },
        newPrevData(state, payload) {
            state.prevData = payload;
        },
        newDaysSince(state, payload) {
            state.daysSince = payload;
        },
        newReadingData(state, payload) {
            state.readingData = payload;
        },
        newSeriesTemp(state, payload) {
            state.seriesTemp = payload;
        },
        newLabelsTemp(state, payload) {
            state.labelsTemp = payload;
        }
    },
    actions: {
        newUserHouseNumber(state, payload) {
            store.commit("newUserHouseNumber", payload);
        },
        newUserName(state, payload) {
            store.commit("newUserName", payload);
        },
        newUserData(state, payload) {
            store.commit("newUserData", payload);
        },
        newMaxReading(state, payload) {
            store.commit("newMaxReading", payload);
        },
        newPrevData(state, payload) {
            store.commit("newPrevData", payload);
        },
        newDaysSince(state, payload) {
            store.commit("newDaysSince", payload);
        },
        newReadingData(state, payload) {
            store.commit("newReadingData", payload);
        },
        newSeriesTemp(state, payload) {
            store.commit("newSeriesTemp", payload);
        },
        newLabelsTemp(state, payload) {
            store.commit("newLabelsTemp", payload);
        }
    },
    getters: {
        userHouseNumber: state => {
            return state.userHouseNumber;
        },
        userName: state => {
            return state.userName;
        },
        userdata: state => {
            return state.userdata;
        },
        maxReading: state => {
            return state.maxReading;
        },
        unit_price: state => {
            return state.unit_price;
        },
        prevData: state => {
            return state.prevData;
        },
        daysSince: state => {
            return state.daysSince;
        },
        readingData: state => {
            return state.readingData;
        },
        seriesTemp: state => {
            return state.seriesTemp;
        },
        labelsTemp: state => {
            return state.labelsTemp;
        }
    }
})