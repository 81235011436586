<template>
  <div class="container">
    <div class="row">
      <div class="col s12 m8 offset-m2">
        <div class="login card-panel grey lighten-2 black--text center display-2">
          <div class="ml-4 pt-2 display-2 text-xs-center">Log In</div>
          <v-form class="pa-4">
            <v-text-field
              label="Email"
              v-model="email"
              prepend-icon="email"
              :rules="formRules"
              :autocomplete="'username'"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="password"
              prepend-icon="lock"
              :rules="formRules"
              :type="'password'"
              :autocomplete="'new-password'"
            ></v-text-field>
            <v-spacer></v-spacer>
            <div class="text-center">
              <v-btn rounded color="primary" dark @click="login" :loading="loading">Log In</v-btn>
            </div>

            <v-spacer></v-spacer>
            <h3 class="red--text text-xs-center">{{ feedback }}</h3>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import firebase from "firebase/app";
import "firebase/auth";
import db from "@/fb";
import mixin from "@/mixin.js";

export default {
  mixins: [mixin],
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      formRules: [v => v.length >= 6 || "Minimum is 6 characters."],
      loading: false,
      feedback: null
    };
  },
  methods: {
    login: function(e) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          data => {
            let thisEmail = this.email;
            let theRouter = this.$router;
            alert(`${data.user.email} is now logged in.`);
            // let isValidUser = this.verifyemail(data.user.email, this.$router);
            var docRef = db.collection("validusers").doc(thisEmail);

            docRef
              .get()
              .then(function(doc) {
                if (doc.exists) {
                  theRouter.push("/readingsAll");
                } else {
                  console.log("No such document!");
                  // theRouter.push("invaliduser");
                }
              })
              .catch(function(error) {
                console.log("Error getting document:", error);
                // theRouter.push("invaliduser");
              });
          },
          err => {
            alert(err.message);
          }
        );
      e.preventDefault();
    }
  },
  computed: {}
};
</script>