<template>
  <div class="readings">
    <v-container fluid class="subheading pt-0">
      <span v-for="reading in readings" :key="reading.id">
        <v-card flat v-if="limitFive(reading.house_number) <= 5" >
          <v-layout row wrap class="pa-2 pl-3 pr-3">
            <v-flex md1>
              <div class="text-xs-left">
                {{ house_counter }}
              </div>
            </v-flex>
            <v-flex md1>
              <div class="text-xs-left">{{ reading.house_number }}</div>
            </v-flex>
            <v-flex md3>
              <div class="text-xs-left">
                {{ reading.created_at }} ({{
                  reading.created_at | whatQuarter
                }})
              </div>
            </v-flex>
            <v-flex md1>
              <div class="text-xs-right">{{ reading.meter_reading }}</div>
            </v-flex>
            <v-flex md1>
              <div class="text-xs-right">{{ reading.units_used }}</div>
            </v-flex>
            <v-flex md1>
              <div class="text-xs-right">{{ reading.unit_price }}</div>
            </v-flex>
            <v-flex md1>
              <div class="text-xs-right">{{ reading.amount_due }}</div>
            </v-flex>
             <v-flex md1>
              <div> yy</div>
            </v-flex>
          </v-layout>
         
        </v-card>

          <v-divider v-if="house_counter == 5">
          </v-divider>

          <div v-if="house_counter == 5">xx</div>
      </span>
    </v-container>
  </div>
</template>

<script>
import db from "@/fb";
import firebase from "firebase/app";
import "firebase/auth";
import mixin from "@/mixin.js";
import moment from "moment";

export default {
  mixins: [mixin],
  // components: { metercolheads, ReadingNew, ReadingEdit, moment },
  data: function () {
    return {
      readings: [],
      maxMeter: 0,
      todayDate: 0,
      house_counter: 0,
      house_ref: 0,
    };
  },
  methods: {
    limitFive(house_num) {
      if (house_num == this.house_ref) {
        this.house_counter++;
      } else {
        this.house_counter = 1;
        this.house_ref = house_num;
      }
      return this.house_counter;
    },
    getUserInfo(theEmail) {
      var docRef = db.collection("validusers").doc(theEmail);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.$store.dispatch("newUserData", doc.data());
            this.$store.dispatch("newUserHouseNumber", doc.data().house_number);
            this.$store.dispatch("newUserName", doc.data().name);
            this.makeReadingsList(doc.data().house_number);
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    storeReading(reading) {
      this.$store.dispatch("newReadingData", reading);
    },
    storePrevData(reading) {
      this.$store.dispatch("newPrevData", reading);
    },
    makeReadingsList() {
      db.collection("readings")
        .orderBy("house_number", "asc")
        .orderBy("created_at", "asc")
        .onSnapshot((res) => {
          const changes = res.docChanges();
          changes.forEach((change) => {
            if (change.type === "added") {
              this.readings.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              });

              if (change.doc.data().meter_reading > this.maxMeter) {
                this.maxMeter = change.doc.data().meter_reading;
                this.$store.dispatch(
                  "newMaxReading",
                  change.doc.data().meter_reading
                );
                this.$store.dispatch(
                  "newDaysSince",
                  this.dayCount(change.doc.data().created_at)
                );
              }
            }
          });
        });
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUser = firebase.auth().currentUser.email;

      this.theUserInfo = this.getUserInfo(this.currentUser);

      this.todayDate = moment(Date.parse(new Date().toString())).format(
        "YYYY-MM-DD"
      );
    }
  },
  computed: {
    userdata() {
      return this.$store.getters.userdata;
    },
  },
};
</script>
