import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDtuV75pKO5xe8kIb7lBejs8fJh78_-wms',
    authDomain: 'myauth-1.firebaseapp.com',
    databaseURL: 'https://myauth-1.firebaseio.com',
    projectId: 'myauth-1',
    storageBucket: 'myauth-1.appspot.com',
    messagingSenderId: '1011547825097',
    appId: '1:1011547825097:web:9ed175216c942df0'
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();


export default db;