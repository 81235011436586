<template>
  <v-layout row justify-end>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Enter New Meter Reading</v-btn>
      </template>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">New Meter Reading</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xs class="px-0 py-0">
              <v-layout wrap class="readingInput">
                <v-flex xs5>
                  <div class="readingLabel">Meter Reading</div>
                  <v-text-field
                    v-validate="'required|integer|min_value:'+ maxReading"
                    v-bind:min="parseInt(maxReading) + 1"
                    v-model="meter_reading"
                    name="meter_reading"
                    type="number"
                    debounce="500"
                  ></v-text-field>
                  <span
                    class="v-alert error"
                    v-show="!(fields.meter_reading && fields.meter_reading.valid)"
                  >This value must be greater than the previous meter reading of: {{maxReading}}.</span>
                </v-flex>
                <v-flex xs7>
                  <div class="readingLabel">Notes</div>
                  <v-textarea name="Notes" v-model="notes" rows="1"></v-textarea>
                </v-flex>

                <v-flex xs12></v-flex>
              </v-layout>

              <v-layout wrap class="meterReadOut" v-if="(gotReading)">
                <v-flex xs4>
                  <div class="readingLabel">Units Used</div>
                  <v-text-field v-model="units_used" box readonly></v-text-field>
                  <div class="readingLabel text-xs-right mt-1">Payment Method:&nbsp;</div>
                </v-flex>

                <v-flex xs8>
                  <div class="readingLabel">Your Cost</div>
                  <v-text-field v-model="amount_due" box readonly></v-text-field>
                  <v-radio-group
                    row
                    class="mt-0"
                    v-model="payment_method"
                    :rules="[v => !!v || 'Select a payment method.']"
                  >
                    <v-radio label="Check" value="Check" checked="checked"></v-radio>
                    
                    <!-- 
                    <v-radio label="Bank Deposit" value="Deposit"></v-radio>
                    <v-radio label="PayPal" value="PayPal"></v-radio>
                    -->

                    <v-alert dense type="info" :value="selectMsg" transition="scale-transition">
                      {{selectMsg}}
                    </v-alert> 

                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              @click="dialog = false; meter_reading = payment_method = ''"
            >Cancel</v-btn>
            <v-btn
              color="success"
              @click="submit"
              :disabled="!(fields.meter_reading && fields.meter_reading.valid)"
            >Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import db from "@/fb";
import mixin from "@/mixin.js";
import moment from "moment";

export default {
  mixins: [mixin],
  data() {
    return {
      valid: true,
      user_name: "",
      loading: false,
      dialog: false,
      meter_reading: "",
      payment_confirmed: "",
      payment_method: "",
      payment_notes: "",
      notes: "",
      created_at: this.todayDate,
      updated_at: this.todayDate
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const reading = {
          house_number: this.userHouseNumber,
          meter_reading: this.meter_reading,
          units_used: this.units_used,
          amount_due: this.amount_due,
          unit_price: this.unit_price,
          units_daily: this.units_daily,
          payment_confirmed: this.payment_confirmed,
          payment_method: this.payment_method,
          payment_notes: this.payment_notes,
          notes: this.notes,
          user_name: this.userName,
          created_at: this.todayDate,
          updated_at: this.todayDate
        };
        db.collection("readings")
          .add(reading)
          .then(() => {
            this.loading = false;
            this.dialog = false;
            // this.$emit("readingAdded");
          });
      }
    }
  },
  created() {
    this.userdata = this.$store.getters.userdata;
    this.todayDate = moment(Date.parse(new Date().toString())).format(
      "YYYY-MM-DD"
    );

    this.user_name = this.$store.getters.userdata.name;
  },
  computed: {
    userName() {
      return this.$store.getters.userName;
    },
    userHouseNumber() {
      return this.$store.getters.userHouseNumber;
    },
    unit_price() {
      return this.$store.getters.unit_price;
    },
    maxReading() {
      return this.$store.getters.maxReading; 
    },
    lstDateReading() {
      return this.$store.getters.lstDateReading;
    },
    daysSince() {
      return this.$store.getters.daysSince;
    },
    units_used() {
      return parseInt(this.meter_reading) - parseInt(this.maxReading);
    },
    units_daily() {
      return (this.units_used / this.daysSince).toFixed(2);
    },
    gotReading() {
      return this.units_used >= 0;
    },
    amount_due() {
      return "$" + (this.units_used * this.unit_price).toFixed(2);
    },
    selectMsg() {
      if (this.payment_method == "Check") {
        return "Make you check payable to Ramadero Water Association and deliver it to 17530 Ramadero Way.";
      } else if (this.payment_method == "Deposit") {
        return "Alert Lee Tyler of the amount deposited.";
      } else if (this.payment_method == "PayPal") {
        return "PayPal PayPal PayPal.";
      } else {
        return "";
      }
    }
  }
};
</script>

<style >
.readingInput input {
  font-family: "Courier New", Courier, monospace;
  font-size: x-large;
  font-weight: bolder;
  color: white;
  letter-spacing: 7px;
}
.meterReadOut input {
  font-size: large;
  font-weight: bolder;
}
.readingInput label,
.readingInput label {
  font-size: medium !important;
}
input {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
</style>
